var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.slides.length)?_c('Slider',{staticClass:"mb-6",class:_vm.$style.slider,attrs:{"slides":_vm.slides,"options":Object.assign({}, _vm.opt, {autoplay: _vm.slider_autoplay}),"name":"BannerSlider","default_pagination":"","default_navigation":""},on:{"slideClick":_vm.handle_click},scopedSlots:_vm._u([{key:"slide",fn:function(ref){
var slide = ref.slide;
return [_c('div',{class:_vm.$style.slide},[_c('img',{class:_vm.$style.slideImg,attrs:{"src":slide,"loading":"lazy","alt":""}})])]}}],null,false,651487456)}):_vm._e(),_c('PageTitle',{attrs:{"hide_btns":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Заявки на участие в конференции ")]},proxy:true},{key:"inner",fn:function(){return [_c('DateTime',{class:_vm.$style.dateTime})]},proxy:true}])}),_c('v-card',{class:_vm.$style.card},[_c('Filters',{class:_vm.$style.filters,on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:[_vm.$style.table, _vm.$style.confTable],attrs:{"headers":_vm.filtered_cols,"items":_vm.conferences,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.conferences_loading,"no-data-text":"Конференции, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.conferences_title",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.conferences_title)+" ")])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.price))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")])]}},{key:"item.name_organization",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.name_organization)+" ")])]}},{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.order_number)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [[_c('ChipStatus',{class:_vm.$style.chip,attrs:{"status":_vm.get_conference_status(item)}},[_vm._v(" "+_vm._s(item.status ? item.status.name : '')+" ")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_c('v-btn',{attrs:{"icon":"","color":"accent darken-4","title":"Редактировать","disabled":_vm.is_disabled(item)},on:{"click":function($event){$event.stopPropagation();return _vm.go_to_edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)}),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false},"default_limit":_vm.default_limit},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }